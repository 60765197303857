import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { $REST_URI } from 'src/environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { BibleReference, SpecificationCategory, SpecificationTag } from '../../model/question.model';
import { $APP_VALUES } from '../../values/app.values';
import { TagResponse } from 'src/app/modules/user/myBkp/myBkp.component';
import { GameResponse } from 'src/app/modules/user/via-pin/via-pin.component';
import { Sieve } from '../../model/sieve.model';
import { AppLanguage } from '../../model/app-language.model';
import { User } from '../../model/user.model';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // appLanguagesData: Observable<AppLanguage>;
  // _appLanguagesData: BehaviorSubject<AppLanguage>;

  appLanguagesData: Observable<any>;
  _appLanguagesData: BehaviorSubject<any>;

  networkEvent: Observable<any>;
  _networkEvent: BehaviorSubject<any>;

  currentUser: User;

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {
    // this._appLanguagesData = new BehaviorSubject<AppLanguage>(null);
    this._appLanguagesData = new BehaviorSubject<any>(null);
    this.appLanguagesData = this._appLanguagesData.asObservable();
    this._networkEvent = new BehaviorSubject<Event>(null);
    this.networkEvent = this._networkEvent.asObservable();
    this.userService.currentUser.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  getSpecificationTagTypes(language: string) {
    return this.http.get<SpecificationTag[]>(`${$REST_URI}/foundation/specificationTag/type?language=${language}`);
  }

  getLanguages() {
    return of($APP_VALUES.languages);
  }

  getVerse(query: BibleReference, language: string) {

    if (!query.toVerse) {
      delete query.toVerse;
    }

    let params = new HttpParams();
    Object.keys(query).forEach(key => {
      params = params.append(key, query[key]);
    });

    params = params.append('language', language);

    if (this.currentUser?.bibleVersion) {
      params = params.append('bibleVersion', this.currentUser.bibleVersion);
    }

    return this.http.get(`${$REST_URI}/foundation/bibleText`, { params });
  }

  getTags(query: TagsQuery) {
    console.log(query)
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        params = params.append(key, query[key]);
      });
    }

    return this.http.get<any[]>(`${$REST_URI}/foundation/specificationTag`, { params });
  }

  getAllTags(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }

    if (this.currentUser?.language) {
      params = params.append('language', this.currentUser.language);
    }

    return this.http.get<TagResponse>(`${$REST_URI}/foundation/specificationTag`, { params });
  }

  updateTag(_id, isInSieveGame) {
    return this.http.put<unknown>(`${$REST_URI}/foundation/specificationTag/isInSieveGame`, { _id, isInSieveGame });
  }

  getBible(language?: any) {
    let params = new HttpParams();
    if (language) {
      params = params.append('language', language);
    }
    // return this.http.get<Bible[]>(`/assets/json/bible.json`);
    return this.http.get<Bible[]>(`${$REST_URI}/foundation/bibleText/bibleBooks`, { params });
  }

  getBibleBooksForAuthor(query: any) {
    console.log(query)
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        params = params.append(key, query[key]);
      });
    }

    return this.http.get<any[]>(`${$REST_URI}/question/getBibleBooksForAuthor`, { params });
  }

  myQuestionDetails(query: any) {
    console.log(query)
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        params = params.append(key, query[key]);
      });
    }

    return this.http.get<any[]>(`${$REST_URI}/question/myQuestionDetails`, { params });
  }

  getGameGroup(query: any) {
    console.log(query)
    let params = new HttpParams();
    params = params.append('pin', query);
    return this.http.get<GameResponse[]>(`${$REST_URI}/game/common/gamePin`, { params });
  }

  getTopics(language: string = 'ENGLISH') {
    return this.http.get<TagResponse>(`${$REST_URI}/foundation/specificationTag?language=${language}&isInSieveGame=true`)
    // .pipe(
    //   map(res => res.sort((a, b) => {
    //     if (a.value < b.value) {
    //       return -1;
    //     }
    //     if (a.value > b.value) {
    //       return 1;
    //     }
    //     return 0;
    //   }))
    // );
  }

  getSieve(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        params = params.append(key, query[key]);
      });
    }
    console.log("params", params);
    return this.http.get<any>(`${$REST_URI}/game/common/presetSieve`, { params });
  }

  questionDetailsForSieve(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/question/questionDetailsForSieve`, { params });
  }

  getAllAppLanguage() {
    return this.http.get<any>(`${$REST_URI}/foundation/appLanguage`);
  }

  // getAppLanguage(language) {
  //   let params = new HttpParams();

  //   params = params.append('language', language);
  //   return this.http.get<AppLanguage>(`${$REST_URI}/foundation/appLanguage`, {params});    
  // }

  getAppLanguage(language) {
    let params = new HttpParams();

    params = params.append('language', language);
    return this.http.get<any>(`${$REST_URI}/foundation/appLanguage`, { params });
  }

  getLanguage() {
    return this.http.get<Language[]>(`${$REST_URI}/foundation/language`);
  }

  getCountryAndCode() {
    return this.http.get<CountryAndCode[]>(`${$REST_URI}/foundation/country`);
  }

  getLevelForAnalysis(id) {
    let params = new HttpParams();

    params = params.append('firebaseUid', id);

    return this.http.get<AppLanguage[]>(`${$REST_URI}/usersquestion/getLevelForAnalysis`, { params });
  }

  getBibleBook(language) {
    let params = new HttpParams();

    params = params.append('language', language);

    return this.http.get<Bible[]>(`${$REST_URI}/foundation/bible`, { params });
  }

  getSimilarQuestion(question: any, statuses?: string[]) {

    let query = new HttpParams();
    if (statuses && statuses.length > 0) {
      query = query.append('status', statuses.toString());
    }

    return this.http.post<any>(`${$REST_URI}/question/checkSimilarQuestions `, question, { params: query });
  }

  getNotificationMessage() {
    return this.http.get<any>(`${$REST_URI}/message`);
  }

  readNotificationMessage(id: string) {
    let data = {};
    return this.http.put<any>(`${$REST_URI}/message/read/${id}`, data);
  }

  sharePreset(data: any) {
    return this.http.post<any>(`${$REST_URI}/game/common/presetSieve/share`, data);
  }

  getWeeklyVerse(data: any) {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        params = params.append(key, data[key]);
      });
    }

    if (this.currentUser?.bibleVersion) {
      params = params.append('bibleVersion', this.currentUser.bibleVersion);
    }

    return this.http.get<any>(`${$REST_URI}/weekly-verse`, { params });
  }

  getOrDownloadQuiz(data: any) {
    // let params = new HttpParams();
    // if (data) {
    //   Object.keys(data).forEach(key => {
    //     // if (data[key]) {
    //     //   if (key === 'filterData') {
    //     //     Object.keys(data[key]).forEach(key1 => {
    //     //       if (data[key][key1] !== null) {
    //     //         params = params.append(key1, data[key][key1]);
    //     //       }
    //     //     });
    //     //   } else {
    //     //   }
    //     //   }
    //     params = params.append(key, data[key]);
    //   });
    // }
    return this.http.post<any>(`${$REST_URI}/question/quiz`, data);
  }

  getMaxQuestionCountForQuiz() {
    // let params = new HttpParams();
    // if (data) {
    //   Object.keys(data).forEach(key => {
    //     if (data[key]) {
    //       params = params.append(key, data[key]);
    //     }
    //   });
    // }
    return this.http.get<any>(`${$REST_URI}/usersquestion/maxQuestionCountForQuiz`);
  }

  getBibleVersion(data: any) {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        params = params.append(key, data[key]);
      });
    }
    return this.http.get<any>(`${$REST_URI}/foundation/bibleVersion`, { params });
  }

  getBiblistLeaderboard(query?: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/user/biblist/author`, { params });
  }


  getVerseLane(query: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          if(key !== 'opponents' && key !== 'verse') {
            params = params.append(key, query[key]);
          }
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/game/scripture-run/bible-verse/lane`, { params });
  }

  getEnemiesChart(query: any) {
    let params = new HttpParams();
    if (query) {
      Object.keys(query).forEach(key => {
        if (key === 'sort') {
          let sortKey = Object.keys(query[key])[0];
          params = params.append(sortKey, query[key][sortKey]);
        } else {
          params = params.append(key, query[key]);
        }
      });
    }
    return this.http.get<any>(`${$REST_URI}/game/scripture-run/leaderBoard/enemies`, { params });
  }


  saveScore(data: any) {
    return this.http.post<any>(`${$REST_URI}/game/scripture-run/leaderBoard`, data);
  }

  getWallLeapLeaderboard() {
    return this.http.get<any>(`${$REST_URI}/game/scripture-run/leaderBoard`);
  }
}

export interface Topic {
  _id: string
  creationDate: Date | string
  language: string
  type: string
  value: string
  parentId?: string
}

export interface Bible {
  bookName: string,
  chaptersCount: number,
  maxVerses: number[]
  osisCode: string,
  goal: number,
  sNo: number,
  _id: string;
}

interface TagsQuery {
  language: string,
  value?: string,
  type?: string,
  parentId?: string,
  _id?: string
}

export interface CountryAndCode {
  callingCode: string,
  code: string,
  name: string,
  _id: string
}

export interface Language {
  code: string,
  googleApiLanguageCode: string,
  name: string
}